<template>
	<main class="page-container" id="page-live-list">
		<div class="container">
			<!-- 탭 -->
			<div class="list-tabs">
				<div class="page-component">
					<div class="tab-group">
						<span class="group-header">방송 구분</span>
						<div class="tab-scroll-wrapper">
							<ul class="tab-list">
								<li class="tab-item" :class="{ 'is-selected': selectBrcCd.includes('99') }" @click="selectBrcTyp('99')" data-broadcastcd="99" name="tabItem1">
									<span class="tab-link"
										><span class="tab-text">전체</span><span class="tab-count">{{ totalCount }}</span></span
									>
								</li>
								<li v-if="liveCnt" class="tab-item" :class="{ 'is-selected': selectBrcCd.includes('10') }" @click="selectBrcTyp('10')" data-broadcastcd="10" name="tabItem1">
									<span class="tab-link"
										><span class="tab-text">LIVE</span><span class="tab-count">{{ liveCnt }}</span></span
									>
								</li>
								<li v-if="cmgCnt" class="tab-item" :class="{ 'is-selected': selectBrcCd.includes('30') }" @click="selectBrcTyp('30')" data-broadcastcd="30" name="tabItem1">
									<span class="tab-link"
										><span class="tab-text">COMMING-SOON</span><span class="tab-count">{{ cmgCnt }}</span></span
									>
								</li>
								<li v-if="vodCnt" class="tab-item" :class="{ 'is-selected': selectBrcCd.includes('20') }" @click="selectBrcTyp('20')" data-broadcastcd="20" name="tabItem1">
									<span class="tab-link"
										><span class="tab-text">VOD</span><span class="tab-count">{{ vodCnt }}</span></span
									>
								</li>
							</ul>
						</div>
					</div>
					<div class="tab-group">
						<span class="group-header">송출 플랫폼</span>
						<div class="tab-scroll-wrapper">
							<ul class="tab-list">
								<li class="tab-item" :class="{ 'is-selected': selectProgramCd.includes('99') }" @click="selectPlt('99')" data-platfomcd="99" name="tabItem">
									<span class="tab-link"
										><span class="tab-text">전체</span><span class="tab-count">{{ pltTotal }}</span></span
									>
								</li>
								<li v-if="pltData.PLATFOM_CNT" v-for="(pltData, idx) in pltList" class="tab-item" :class="{ 'is-selected': selectProgramCd.includes(pltData.PLATFOM_CD) }" :data-platfomcd="pltData.PLATFOM_CD" name="tabItem" @click="selectPlt(pltData.PLATFOM_CD)" :key="idx">
									<span class="tab-link"
										><span class="tab-text">{{ pltData.PLATFOM_NM }}</span
										><span class="tab-count">{{ pltData.PLATFOM_CNT }}</span></span
									>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- //탭 -->
			<div>
				<div class="list-top-container is-borderless">
					<div class="top-column">
						<div class="top-header">
							<h3 class="title">라이브홍보</h3>
							<span class="count">{{ listTotal }}</span>
						</div>
					</div>
					<div class="top-column">
						<div class="top-options">
							<ul class="option-list">
								<li class="option-item" :class="{ 'is-selected': sortStatus }" @click="sortChange('04')" data-name="newDscenSort"><span class="option-text">최신순</span></li>
								<li class="option-item" :class="{ 'is-selected': !sortStatus }" @click="sortChange('02')" data-name="inqcntSort"><span class="option-text">조회순</span></li>
								<li class="option-item">
									<router-link to="/live/schedule" class="boradcast-button-cal"> <i class="icon-tv"></i><span>편성표보기</span><i class="icon-arrow-right"></i> </router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="grid-container live-list-container">
					<ul class="grid-list">
						<li v-for="(brcItem, idx) in brcInfoList" :key="idx" class="grid-item">
							<!-- <live-comm-card :brcInfo="brcItem" /> -->
							<live-video-card :brcInfo="brcItem" :key="`${brcItem.BRC_INFO_NO}_SALT_${componentKey}`" />
						</li>
					</ul>
				</div>
				<portal-pagination page-name="LiveList" 
					:pageListSize="pageListSize"
					:total-count="listTotal" 
					:page-size="pageSize" 
					:page-no="pageNo" 
					:query="queries"
				></portal-pagination>
			</div>
		</div>
	</main>
</template>

<script>
import LiveCommCard from '@/components/live/LiveCommCard';
import LiveVideoCard from '@/components/live/LiveVideoCard';
import PortalPagination from '../../../components/PortalPagination';
import { ACT_GET_LIVE_COMMERCE_LIST } from '@/store/_act_consts';
import { queryToValue, setPaging } from '@/assets/js/utils';
import { mapGetters } from 'vuex';

export default {
	name: 'LiveList',
	components: { LiveCommCard, LiveVideoCard, PortalPagination },
	data: () => ({
		totalCount: 0,
		pageListSize: 10,
		pageSize: 20,
		pageNo: 1,
		brcInfoList: [],
		pltList: [],
		vodCnt: 0,
		liveCnt: 0,
		cmgCnt: 0,
		sortCd: '04',
		brcTyp: '01',
		sortStatus: true,
		brcStsCd: [],
		selectBrcCd: [],
		programCd: [],
		selectProgramCd: [],
		pltTotal: 0,
		listTotal: 0,

		componentKey: 0,
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			return result;
		},
	},
	watch: {
		$route(to) {
			this.pageNo = queryToValue(to.query.pageNo, true);
			// this.programCd = queryToValue(to.query.programCd, true);
			// this.selectBrcCd = queryToValue(to.query.selectBrcCd, true);
			this.getLiveBrcList();
		},
	},
	created() {
		const vgControllerScript = document.createElement('script');
		vgControllerScript.setAttribute('src', 'https://file.kollus.com/vgcontroller/vg-controller-client.latest.min.js');
		document.head.appendChild(vgControllerScript);

		this.pageListSize = (this.isMobile) ? 5 : 10;
	},
	mounted() {
		this.pageNo = queryToValue(this.$route.query.pageNo, true);
		// this.programCd = queryToValue(this.$route.query.programCd, true);
		// this.selectBrcCd = queryToValue(this.$route.query.selectBrcCd, true);
		this.getLiveBrcList();
	},
	methods: {
		getLiveBrcList() {
			// selectbrc를 컨버팅 해서 brcsts 값 셋팅
			if (this.selectBrcCd.includes('99') || this.selectBrcCd.length <= 0) {
				this.brcStsCd = ['07', '08', '09', '10', '20'];
			} else {
				this.selectBrcCd.map((selCd) => {
					// 기존 데이터 초기화
					if (this.selectBrcCd.includes('99')) this.brcStsCd = [];

					if (selCd === '10' || (selCd === '20' && !this.brcStsCd.includes(selCd))) {
						this.brcStsCd.push(selCd);
					} else if (selCd === '30' && !this.brcStsCd.includes(selCd)) {
						if (!this.brcStsCd.includes('07') || !this.brcStsCd.includes('08') || !this.brcStsCd.includes('09')) this.brcStsCd.push('07', '08', '09');
					}
				});
			}

			if (this.selectProgramCd.includes('99')) this.programCd = [];
			else this.programCd = this.selectProgramCd;

			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_COMMERCE_LIST}`, {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
					totalCount: this.totalCount,
					brcStsCd: this.brcStsCd.toString(),
					programCd: this.programCd.toString(),
					brcTyp: this.brcTyp,
					sortCd: this.sortCd, // 최신순: 04, 조회순: 02
					rpYn: 'Y',
				})
				.then((res) => {
					setPaging(this, res);
					this.brcInfoList = res.broadcasts.LIST;
					this.totalCount = res.broadcasts.TOT_CNT;
					this.liveCnt = res.broadcasts.LIV_CNT;
					this.vodCnt = res.broadcasts.VOD_CNT;
					this.cmgCnt = res.broadcasts.CMG_CNT;
					this.comparePltData(res.broadcasts);
					this.listTotal = this.getSelectPltListTotal(res.broadcasts.PLATFOM_CNT_LIST);
				})
				.catch((e) => {
					this.brcInfoList = [];
				})
				.finally(() => {
					this.componentKey++;
				});
		},
		comparePltData(data) {
			this.pltList = data.PLATFOM_LIST.map((pltList) => {
				const resultList = data.PLATFOM_CNT_LIST.find((pltCnt) => pltCnt.PLATFOM_CD === pltList.PLATFOM_CD);
				return resultList ? { ...pltList, ...resultList } : pltList;
			});
			this.pltTotal = data.PLATFOM_CNT_LIST.reduce((sumval, pltCnt) => sumval + pltCnt.PLATFOM_CNT, 0);
		},
		sortChange(sort) {
			if (sort === '04') {
				this.sortStatus = true;
			} else if (sort === '02') {
				this.sortStatus = false;
			}
			this.sortCd = sort;
			this.getLiveBrcList();
		},
		selectBrcTyp(brcTyp) {
			// 전체 조회일 경우 배열을 비우고 99번만 넣어서 리턴
			this.brcStsCd = [];

			if (brcTyp === '99') {
				// 전체 조회 시 배열을 비우고 99번만 추가
				this.selectBrcCd = ['99'];
			} else {
				const idx = this.selectBrcCd.indexOf(brcTyp);
				const isTotal = this.selectBrcCd.includes('99');
				if (isTotal) {
					this.selectBrcCd.splice(this.selectBrcCd.indexOf('99'), 1);
				}

				if (idx === -1) {
					// 배열에 값이 존재하지 않을 경우 방송 타입 추가
					this.selectBrcCd.push(brcTyp);
				} else {
					// 배열에 값이 존재할 경우 방송 타입 제거
					this.selectBrcCd.splice(idx, 1);
				}
			}
			this.getLiveBrcList();
		},
		selectPlt(prgCd) {
			// 전체 조회일 경우 배열을 비우고 99번만 넣어서 리턴
			if (prgCd === '99') {
				// 전체 조회 시 배열을 비우고 99번만 추가
				this.selectProgramCd = ['99'];
			} else {
				const idx = this.selectProgramCd.indexOf(prgCd);
				const isTotal = this.selectProgramCd.includes('99');
				if (isTotal) this.selectProgramCd.splice(this.selectProgramCd.indexOf('99'), 1);

				if (idx === -1) {
					// 배열에 값이 존재하지 않을 경우 방송 타입 추가
					this.selectProgramCd.push(prgCd);
				} else {
					// 배열에 값이 존재할 경우 방송 타입 제거
					this.selectProgramCd.splice(idx, 1);
				}
			}
			this.pageNo = 1;
			this.getLiveBrcList();
		},
		getSelectPltListTotal(pltCount) {
			if (this.selectProgramCd.includes('99') || this.selectProgramCd.length <= 0) {
				return this.pltTotal;
			} else {
				const diffList = this.selectProgramCd.map((programCd) => pltCount.find((plt) => plt.PLATFOM_CD === programCd));
				return diffList.reduce((sumval, pltCnt) => sumval + pltCnt.PLATFOM_CNT, 0);
			}
		},
	},
};
</script>
